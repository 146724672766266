<template>
  <div>
    <span
      ><img style="width: 100%" src="../assets/image/home/banner.jpg"
    /></span>
  </div>

  <div class="details">
    <el-row :gutter="20">
      <el-col>
        <div style="text-align: left">
          <span style="font-size: 13px">您当前的位置：</span>
          <el-link @click="toIndex">首页</el-link>
          <span style="font-size: 13px">&nbsp;>&nbsp;</span>
          <el-link @click="toProduct">产品中心</el-link>
          <span style="font-size: 13px">&nbsp;>&nbsp;</span>
          <el-link @click="toCurrent">产品详情</el-link>
        </div>
        <p class="details-name">{{ name }}</p>
        <span>发布时间：{{ date }}</span>
        <p class="details-line"></p>
        <p class="details-describe" style="text-align: left">{{ describe }}</p>
        <img :src="img" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "About",
  components: {
    // HelloWorld,
  },

  data() {
    return {
      img: "",
      name: "",
      describe: "",
      date: "",
    };
  },
  methods: {
    toIndex() {
      this.$router.push({
        path: "/index",
      });
    },
    toProduct() {
      this.$router.push({
        path: "/product",
      });
    },
    toCurrent() {
      this.$router.push({
        path: "/productDetails",
      });
    },
  },
  created: function () {
    this.img = this.$route.query.img;
    this.name = this.$route.query.name;
    this.describe = this.$route.query.describe;
    this.date = this.$route.query.date;
  },
};
</script>

<style lang="scss" scoped>
.details {
  margin-top: 30px;
  margin-bottom: 50px;
  padding-left: 270px;
  padding-right: 270px;
}
.details-name {
  font-size: 20px;
}
.details span {
  font-size: 15px;
}
.details-line {
  width: 100%;
  border-bottom: 1px solid hsla(240, 8%, 85%, 0.871);
}
.details-describe {
  margin-top: 50px;
  font-size: 16px;
}
.details img {
  margin-top: 50px;
}
</style>